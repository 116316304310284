import React, { useEffect } from 'react';
import './Home.css';

export default function Home(props) {
  const body = document.body;

  useEffect(() => {
    body.classList.add('prevent-scroll');

    return () => {
      body.classList.remove('prevent-scroll');
    };
  }, [body]);

  function windowQuery() {
    const width = window.innerWidth;

    if (width <= 768) {
      return 'sm';
    } else if (width > 768 && width <= 1024) {
      return 'md'
    } else {
      return 'lg'
    };
  };

  return (
    <div
      className='home-container'
      style={{ backgroundImage: props.bgImg ? `url(${props.bgImg}-${windowQuery()}.jpg)` : 'none' }}
    >
      <h1>Brie</h1>
      <p>
        photographer.<br />
        full stack engineer.<br />
        your favorite cheese.
      </p>
    </div>
  );
};