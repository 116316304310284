import React, { useEffect, useRef } from 'react';
import './WorkContainer.css';

export default function WorkContainer(props) {
  const carousel = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    carousel.current.scrollTo(0,0);
    props.changePage(props.page);
  });

  return (
    <div className='work-container-container'>
      <h1>{props.page}</h1>

      <div className='carousel' ref={carousel}>
        { props.imageList.map(src => {
          return (
            <picture key={`${props.slug}/${props.page}/${src}pic`} >
              <source
                media="(max-width: 768px)"
                data-srcset={`${props.slug}/${props.page}/${src}-sm.jpg`}
              />
              <source
                media="(min-width: 769px) and (max-width: 1024px)"
                data-srcset={`${props.slug}/${props.page}/${src}-md.jpg`}
              />
              <source
                media="(min-width: 1025px)"
                data-srcset={`${props.slug}/${props.page}/${src}-lg.jpg`}
              />
              <img
                key={src}
                className='lazy'
                data-src={`${props.slug}/${props.page}/${src}-lg.jpg`}
                alt={src}
              />
            </picture>
          );
        }) }
      </div>
    </div>
  );
};
